import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import { AiOutlineMail } from "react-icons/ai";
import Iit from "../../images/iit.png";
import Sliit from "../../images/sliit.png";
import Mora from "../../images/mora.png";
import Uva from "../../images/uva.jpg";
import interImg from "../../images/InternImg.png"
import axios from "axios";
import plus from "../../images/icons/plus.svg";
import minus from "../../images/icons/minus.svg";
import map from "../../images/icons/map-pin.svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const Careers = (props) => {
  const [careers, setCareers] = useState([]);
  useEffect(async () => {
    try {
      const results = await axios.get(
        "https://analytics-internal.itelasoft.com.au/PublicData/JobOpenings",
        {
          headers: {
            "x-api-key": `6fa78d2c16d34c6aa31bd6073ae86b5b`,
          },
        }
      );
      setCareers(results.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Layout bodyClass="page-careers">
      <Seo
        title="Careers"
        description="Are you a
            passionate and committed technology enthusiast? We have room for you
            in our growing team!"
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
          />
        </div>
        <div className="container">
          <div className="row main-page">
            <div className="col-12">
              <h1 className="page-title">Careers</h1>
              <h2 className="page-subtitle">
                Do you have a passion for IT? We'd love to have you onboard
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container py-4">
          <h3 className="title-3">Are you our next star?</h3>
          <p>
            We are a growing dynamic software development company delivering
            technology solutions exclusively for the international markets. Our
            learning-based, innovative coaching culture expands your horizon of
            knowledge and grooms you in a world-class career. Are you a
            passionate and committed technology enthusiast? We have room for you
            in our growing team!
          </p>
          <h3 className="title-3 mt-5">
            What can you experience at iTelaSoft?
          </h3>
          <p>
            At iTelaSoft, we love to innovate, do something great, make clients
            happy and have fun! We don’t have complicated hierarchies, and
            everyone is empowered to take ownership and show their best to the
            customers. We value honesty, respecting everyone, and fairness. If
            you are smart, value oriented, committed, hard working and
            performing, sky is the only limit for your growth at iTelaSoft. To
            have a flavour of what we do at iTelaSoft and how it feels like to
            be there, check our{" "}
            <span className="highlight-link">
              <Link to="https://www.youtube.com/channel/UCEBSHvKia8sRxDu8RbgWOqA">
                YouTube Channel
              </Link>
            </span>
            .
          </p>
          {/* affliated unis  */}
          <div className="affliated-unis-section">
            <h3 className="title-3 mt-5">Affiliated Universities</h3>
            <div className="row mt-4 mb-4">
              <div className="col-6 col-md-3 uni-logo ">
                <img alt="Services" className="img-fluid" src={Iit} />
              </div>
              <div className="col-6 col-md-3 uni-logo ">
                <img alt="Services" className="img-fluid" src={Sliit} />
              </div>
              <div className="col-6 col-md-3 uni-logo ">
                <img alt="Services" className="img-fluid" src={Mora} />
              </div>
              <div className="col-6 col-md-3 uni-logo ">
                <img alt="Services" className="img-fluid" src={Uva} />
              </div>
            </div>
          </div>

          {/* careers  */}
          <div className="careers-section">
            <h4 className="title-3 mt-5 mb-3">
              We are looking for the following roles
            </h4>

            {/* card-deck */}

            <div class="card-deck carrer-card-deck">
              {careers.map((data) => (
                <React.Fragment key={data.id}>
                  {data.publish ? (
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title"> {data.title}</h5>
                        {data.description ? (
                          <div
                            className="description"
                            dangerouslySetInnerHTML={{
                              __html: data.description,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="card-footer">
                        <div>
                          <img className="map" src={map} />
                          <span className="location-text">
                            {data.location.split(",").join(", ")}
                          </span>
                        </div>
                        {/* <a href="#" class="btn btn-outline">see more</a> */}
                        <Link
                          className="btn btn-outline"
                          to={
                            "/careers/apply/?id=" +
                            data.id +
                            "&title=" +
                            data.title
                          }
                        >
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
            </div>

            {/* Internship section */}
            <div class="card-deck carrer-card-deck">
              <div class="card intetn-card">
                <div
                  className="col-lg-12 card-top-line"
                  style={{
                    backgroundColor: "#FFC700",
                    height: "16px",
                    borderRadius: "0.5rem 0.5rem 0 0",
                  }}
                ></div>
                <div class="card-body inter-card-body">
                  <div className="card-content-wrapper">
                    <div className="col-lg-4 col-md-6 card-image-wrapper d-none d-lg-flex d-md-flex">
                      <img
                        src={interImg}
                        alt="interImg"
                        className="card-img img-fluid"
                      />
                    </div>
                    <div className="col-lg-8 col-md-6 card-text-wrapper">
                      <h5 class="card-title">Internships at Itelasoft</h5>
                      <div className="description">
                        We offer exciting internship opportunities for motivated
                        individuals looking to gain valuable work experience in
                        a dynamic and innovative environment. We believe that
                        internships provide an excellent platform for students
                        and recent graduates to apply their academic knowledge,
                        develop essential skills, and explore their career
                        interests.We are excited to welcome talented individuals
                        who are eager to make a difference and embark on a
                        rewarding journey with us.
                      </div>
                      {/* <div class="card-footer intern-card-footer mt-1">
                        <Link className="btn btn-outline">Apply Now</Link>
                      </div> */}
                      <div class="card-footer intern-card-footer mt-1">
                        <div>
                        <img class="map intern-location-icon" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItbWFwLXBpbiI+PHBhdGggZD0iTTIxIDEwYzAgNy05IDEzLTkgMTNzLTktNi05LTEzYTkgOSAwIDAgMSAxOCAweiI+PC9wYXRoPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTAiIHI9IjMiPjwvY2lyY2xlPjwvc3ZnPg==" />
                          <span className="location-text">
                            <span>Sri Lanka, India</span>
                          </span>
                        </div>
                        <Link className="btn btn-outline" to='/careers/Internship'>
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ebd-card-deck */}
            {/* <div className="wrap-car-card">
              {careers.map((data) => (
                <React.Fragment key={data.id}>
                  {data.publish ? (
                    <div class="card-cr">

                      <>
                        <div class="card-header-cr">
                          {data.title}
                        </div>
                        <div class="card-body-cr">
                          {data.description ? (

                            <div
                              className="description"
                              dangerouslySetInnerHTML={{
                                __html: data.description,
                              }}
                            />

                          ) : (
                            ""
                          )}

                        </div>
                        <footer>
                          <div className="card-footer-cr">  <div><img className="map" src={map} />
                            <span className="location-text">{data.location}</span></div>
                            <Link
                              className="btn btn-outline"
                              to={
                                "/careers/apply/?id=" +
                                data.id +
                                "&title=" +
                                data.title
                              }
                            >
                              Apply Now
                            </Link>
                          </div>
                        </footer>
                      </>

                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
            </div> */}

            {/* <Accordion>
              {careers.map((data) => (
                <React.Fragment key={data.id}>
                  {data.publish ? (
                    <AccordionItem dangerouslySetExpanded={true}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          {data.title} <img className="plus" src={plus} />
                          <img className="minus" src={minus} />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="career card">
                          {console.log("career data : ", data)}
                          <div className="card-body">
                            <h5 className="location">
                              <img className="map" src={map} />
                              {data.location}
                            </h5>
                            {data.description ? (
                              <div className="description">
                                <h5>The Opportunity</h5>
                                <div
                                  className="description"
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {data.coreSkills ? (
                              <div className="coreSkills">
                                <h5>Skills You Need</h5>
                                <p>{data.coreSkills}</p>
                              </div>
                            ) : (
                              ""
                            )}

                            {data.responsibilities ? (
                              <div className="responsibilities">
                                <h5>You will Engage In</h5>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.responsibilities,
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {data.qualifications ? (
                              <div className="qualifications">
                                <h5>What would Make You Stand Out</h5>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.qualifications,
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            <Link
                              className="btn btn-primary"
                              to={
                                "/careers/apply/?id=" +
                                data.id +
                                "&title=" +
                                data.title
                              }
                            >
                              Apply Now
                            </Link>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ))}
            </Accordion> */}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="career-banner">
              <div className="career-banner-img">
                <Img
                  fluid={props.data.contactImg.childImageSharp.fluid}
                  loading="eager"
                  fadeIn={true}
                  alt="contact image"
                />
              </div>
              <div className="wrap-detail">
                <div className="contact"></div>
                <div className="wrap-mail">
                  <AiOutlineMail />
                  <h1 className="car-title">careers@itelasoft.com</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/careers/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            applylink
          }
        }
      }
    }
    bannerImg: file(relativePath: { eq: "about3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    contactImg: file(relativePath: { eq: "contactus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Careers;
